import { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTransition, animated } from "react-spring";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { Boop } from "../Animations/Boop";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { getCardLayoutInfo } from "../../../modules/getCardLayoutInfo";
import Modal from "react-bootstrap/Modal";
import "./styles.scss";

export const CardElementMaterials = ({ itemsStateObj, basesArray, materialEditingStatus }) => {
  const [show, setShow] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const cardLayoutInfo = getCardLayoutInfo(2);

  const dataToDisplayTransitionArray = useTransition(basesArray, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  const ProductDetailsModal = ({ show, modalDetails }) => (
    <Modal className="ProductDetailsModal" show={show} size="md" style={{ zIndex: 2002 }} onHide={handleCloseModal} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalDetails.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {modalDetails?.youtubeSrc && (
            <iframe
              className="mx-auto my-4"
              width="85%"
              height="250"
              src={modalDetails.youtubeSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
          {modalDetails?.image && (
            <ImgWithFallback src={modalDetails.image} fallback={`${modalDetails.image.split(".")[0]}.png`} alt={modalDetails.title} className="mx-auto my-4" />
          )}
          <h5 className="mb-2">Description</h5>
          <p>{modalDetails.description}</p>
          <a href={modalDetails.productPage} target="_blank" rel="noopener noreferrer" className="btn btn-primary mx-auto mt-1 mt-md-4">
            Learn More
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );

  const handleCardClick = (material) => {
    let copy = { ...itemsStateObj.state.activeIds };
    copy.materials[materialEditingStatus] = { _id: material._id };

    itemsStateObj.setState(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - MojoDesk",
      itemSelected: `${materialEditingStatus} - ${material.displayName}`,
      page: "materials",
    });
  };

  const handleSeeProductDetailsClick = (e, _modalDetails) => {
    setModalDetails({ ..._modalDetails });
    handleShowModal();
  };

  return (
    <Row className="CardElementMaterials mt-3">
      {itemsStateObj.state.activeIds.desk_model._id.includes("battle_station") && (
        <div className="d-flex justify-content-center align-items-center">
          <p className="ms-1 mb-2 text-center" style={{ color: "#4F4F4F" }}>
            Carbon fiber top + black base are included in this bundle
          </p>
        </div>
      )}
      {dataToDisplayTransitionArray((style, card, key, index) => {
        if (card.excluded) return null;
        const isActive = itemsStateObj.state.activeIds.materials[materialEditingStatus]._id === card._id;

        return (
          <Col xs={cardLayoutInfo.xs} md={cardLayoutInfo.md} key={card._id} className="px-2">
            <animated.div style={style}>
              <Card className={`mb-3 itemsCard ${isActive ? "active" : ""}`} style={{ width: cardLayoutInfo.width }} onClick={() => handleCardClick(card)}>
                {isActive ? <img className="checkMark" alt="check mark" src="/images/checkMark.svg" /> : null}
                {card?.metaData?.onSale && (
                  <div className="ms-auto">
                    <img alt="new" className="saleBadge" src="/images/badgeSale.png" />
                  </div>
                )}

                <div className="my-2 d-flex mx-auto">
                  <ImgWithFallback src={card.thumbnail[0]} fallback={card.thumbnail[1] || card.thumbnail[0]} alt={card.displayName} className="card-img-top no-top-radius" />
                </div>
                <Card.Body className="py-1 d-flex flex-column justify-content-center align-items-center">
                  <h6 className="title">{card.displayName} </h6>
                  {card?.description && <p className="description mb-1">{card.description} </p>}
                  {card?.modalDetails && (
                    <div className="d-flex justify-content-center my-1">
                      <Boop boopType="scale" scale={1.03} timing={200}>
                        <button id="modalButton" onClick={(e) => handleSeeProductDetailsClick(e, card.modalDetails)}>
                          more info
                        </button>
                      </Boop>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </animated.div>
          </Col>
        );
      })}
      <ProductDetailsModal show={show} modalDetails={modalDetails} />
    </Row>
  );
};
